// Disclaimer

import Disclaimer from '../../lib/disclaimer'

const disclaimer = new Disclaimer({
  show: function() {
    document.querySelector('body').classList.add('disclaimed');
    document.querySelector('.disclaimer-container').style.display = 'flex';
  },
  hide: function() {
    document.querySelector('body').classList.remove('disclaimed');
    document.querySelector('.disclaimer-container').style.display = 'none';
  }
}).init();
